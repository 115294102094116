<template>
  <div>
    <Button
      type="button"
      class="btn btn-danger"
      :disabled="campaign.status == 'FINISHED'"
      :loading="loader"
      v-b-modal="'end-campaign-modal'"
    >
      Encerrar Campanha
    </Button>
    <EndCampaignModal
      :campaign="campaign"
      @ended="handleEndedCampaign"
    />
  </div>
</template>

<script>

import Button from '@/components/common/Button/Button'
import EndCampaignModal from '@/components/campaigns/Modal/EndCampaignModal'

export default {
  name: 'EndCampaignButton',
  props: ['campaign'],
  components: {
    Button,
    EndCampaignModal
  },
  data () {
    return {
      loader: false
    }
  },
  created () {
  },
  methods: {
    handleEndedCampaign () {
      this.$emit('ended', this.campaign)
    }
  }
}
</script>
