import axios from '@/plugins/axios'

/**
 * Get activity logs
 */
const getActivityLogs = async ({
  type = null,
  // eslint-disable-next-line camelcase
  loggable_type,
  // eslint-disable-next-line camelcase
  loggable_id
}) => {
  try {
    const response = await axios.get('admin/logs', {
      params: {
        type,
        loggable_type,
        loggable_id
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  getActivityLogs
}
