<template>
  <b-modal
    :id="`end-campaign-modal`"
    :modal-class="'default-modal'"
    header-class="justify-content-between"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >

    <template #modal-header="{ close }">
      <div class="text-center">
        <h2 class="highlighted-color1">
          <span>Encerrar campanha</span>
        </h2>
      </div>
      <!--begin::Close-->
      <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Close" @click="close()">
          <span class="svg-icon svg-icon-1">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor"></rect>
            <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor"></rect>
            </svg>
          </span>
      </div>
      <!--end::Close-->
    </template>

    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <p>Você tem certeza que deseja encerrar a campanha <strong>{{ campaign.name }}</strong>?</p>
      <div class="mt-3 text-center">
        <Button
          type="button"
          class="btn btn-danger"
          @click="submit"
          :loading="loader"
        >
          Encerrar campanha
        </Button>
      </div>
    </div>
  </b-modal>
</template>

<script>

import CampaignsService from '@/modules/campaigns/services/campaigns-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'EndCampaignModal',
  props: ['campaign'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false
    }
  },
  methods: {
    /**
      * On modal hidden
      */
    hidden () {
      this.campaign = null
      this.content = null
      this.form.line_id = ''
    },
    /**
      * On modal shown
      */
    shown () {
    },
    /**
      * Submit
    */
    async submit () {
      this.loader = true
      this.content = null
      try {
        await CampaignsService.endCampaign(this.campaign.id)
        this.$bvModal.hide('end-campaign-modal')
        this.$emit('ended', this.campaign)
      } catch (error) {
        console.log(error)
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
